import React from 'react';
import Layout from '../../components/Layout';


export default function Index() {

    return (
        <Layout allowAnonymous={false}>
            
        </Layout>
    )
}